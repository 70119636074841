

export const serverHost = "https://fullrunners.net"

export const Apiurl = serverHost + "/api/v1/"

//CONFIG

export const minLengPassword = 6

//PATHS

export const PATH_LOGIN = Apiurl + "login"
export const PATH_GET_USER = Apiurl + "get-user"
export const PATH_SAVE_EVENTS = Apiurl + "save/eventos/primero"


export const PATH_GET_EVENTS_TABLE = Apiurl + "get/eventos/primero"
export const PATH_DELETE_EVENTS_TABLE = Apiurl + "delete/eventos/primero"

export const PATH_GET_EVENT_ID = Apiurl + "get/event/primero"
export const PATH_UPDATE_EVENT_ID = Apiurl + "update/event/primero"
export const PATH_GET_EVENTS_BGMAIN = Apiurl + "get/background-imagen-main"
export const PATH_SAVE_EVENTS_BGMAIN = Apiurl + "save/background-imagen-main"
export const PATH_GET_EVENT_BGMAIN_BYID = Apiurl + "get/background-imagen-first"

export const PATH_DELETE_EVENT_BGMAIN_BYID = Apiurl + "delete/background-imagen-first"
export const PATH_UPDATE_EVENT_BGMAIN_BYID = Apiurl + "update/background-imagen-first"

export const PATH_GET_DISTANCES = Apiurl + "get-distances"
export const PATH_SAVE_DISTANCE_EVENT = Apiurl + "eventos/save-category"


export const PATH_GET_SUSCRIBER_TABLE = Apiurl + "get-suscribers"
export const PATH_GET_SUSCRIBER_ID = Apiurl + "get-suscriber"
export const PATH_GET_SUSCRIBERS_TALLAS = Apiurl + "get-tallas"
export const PATH_UPDTE_SUSCRIBER = Apiurl + "update-suscriber"
export const PATH_GET_DISTANCE_EVENT_BYID = Apiurl + "event/get-distance"
export const PATH_SUBMITRESULTADOS = Apiurl + "event/submit-resultados"




//IMAG
export const PATH_NOIMAGEN_AVAILABLE = "https://www.fullrunners.net/images/no-imagen-available.svg"
export const PATH_IMAGENS_EVENTS = "https://www.fullrunners.net/events-flyer"
export const PATH_IMAGENS_EVENTS_ESPONSOR = "https://www.fullrunners.net/events-sponsor"
export const PATH_IMAGENS_BACKGROUND_IMAGEN = "https://www.fullrunners.net/portada"

export const PATH_IMAGENS_EVENTS_INSCRIPTION = "https://www.fullrunners.net/events-inscriptions-flayer"
export const PATH_IMAGENS_EVENTS_RESULTADOS = "https://www.fullrunners.net/events-resultados-flayer"



export const PATH_SEND_IMPORT_EXCELL = Apiurl + "event/import-compoetidores"
export const PATH_GET_COMPETIDORES_LIST = Apiurl + "events/compoetidores"
export const PATH_GET_CARRERAS_LIST = Apiurl + "events/compoetidores-carreras"
export const PATH_DELETE_COMPETIDOR = Apiurl + "events/compoetidores-eliminar"

export const PATH_ENVIAR_CORREOS_COMPETIDORES = Apiurl + "enviar/correos-compoetidores"

export const PATH_GUARDAR_IMG_PUBLICIDAD = Apiurl + "save/imagen-publicidad"

export const PATH_LISTAR_IMG_PUBLICIDAD = Apiurl + "listar/imagen-publicidad"


export const PATH_DELETE_IMG_PUBLICIDAD = Apiurl + "delete/imagen-publicidad"


export const PATH_SAVE_LOGO_SPONSOR = Apiurl + "save/imagen-sponsors"
export const PATH_LIST_LOGO_SPONSOR = Apiurl + "listar/imagen-sponsors"
export const PATH_DELETE_LOGO_SPONSOR = Apiurl + "delete/imagen-sponsors"



export const getStatusList = () => {

    return [
        { id: 1, text: "ACTIVE" },
        { id: 2, text: "INACTIVE" },
        { id: 3, text: "EXPIRE" },
        { id: 4, text: "ON" }]
}
export const getStatusInscriptionList = () => {

    return [
        { id: 1, text: "APPROVED" },
        { id: 2, text: "PENDING-REVIEW" },
        { id: 3, text: "DENIED" }]
}

export const getGenderInscriptionList = () => {

    return [
        { id: 1, text: "M" },
        { id: 2, text: "F" },
        { id: 3, text: "OTROS" }]
}