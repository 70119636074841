import React, { useEffect, useState } from 'react'
import Pagination from '../Pagination'
import Footer from '../Footer'
import Nav from '../Nav'
import ScrollTop from '../ScrollTop'
import ModalImportExcell from './ModalImportExcell';
import apiSecure from '../../services/servicedba'
import { PATH_DELETE_COMPETIDOR, PATH_GET_CARRERAS_LIST, PATH_GET_COMPETIDORES_LIST } from '../../services/utils';
import Select2Multiple from '../Select2Multiple'
import ModalEnvioCorreos from './ModalEnvioCorreos'

export default function Competidores() {

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenModalEnvioCorreos, setIsOpenModalEnvioCorreos] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [enableSearch, setEnableSearch] = useState(true)
    const [competidorsList, setCompetidorsList] = useState([])
    const [totalRegister, setTotalRegister] = useState(0)
    const [carrerasList, setCarrerasList] = useState([])
    const [carrerasListSelected, setCarrerasListSelected] = useState([])

    const [selectedItems, setSelectedItems] = useState([])
    const [isSelectedAll, setIsSelectedAll] = useState(false)

    const [emailTest, setEmailTest] = useState('')


    useEffect(() => {

        const getData = async () => {

            let { data } = await apiSecure.post(PATH_GET_CARRERAS_LIST, { token: localStorage.getItem('token') })

            const carreras = data.map(item => item.carrera);
            setCarrerasList(carreras);

        }
        getData()

    }, [])

    useEffect(() => {

        if (enableSearch) {
            ///GET DATA
            const getData = async (page) => {

                let { data } = await apiSecure.post(PATH_GET_COMPETIDORES_LIST + "?page=" + page, { token: localStorage.getItem('token'), carreras: carrerasListSelected })

                setCompetidorsList(data.data)
                setLastPage(data.last_page)
                setTotalRegister(data.total)

                if (isSelectedAll && data.data.length) {
                    setSelectedItems(data.data.map(item => item.id))
                }
            }
            //
            getData(currentPage)

            setEnableSearch(false)
        }
    }, [currentPage, enableSearch])



    const onChangeCorreoPrueba = (value) => {
        setEmailTest(value)
        setSelectedItems(value)
    }

    const onChangeFilter = async (val) => {
        setCarrerasListSelected(val)
        setCompetidorsList([])
        setLastPage(1)
        setTotalRegister(0)

        let { data } = await apiSecure.post(PATH_GET_COMPETIDORES_LIST + "?page=" + 1, { token: localStorage.getItem('token'), carreras: val })
        setCompetidorsList(data.data)
        setLastPage(data.last_page)
        setTotalRegister(data.total)



    }

    const modalImportExcellCompetidores = () => {
        setIsOpenModal(true)
    }

    const modalEnviarCorreos = () => {
        // console.log('selectedItems', selectedItems)
        setCarrerasListSelected(selectedItems)
        if (!isSelectedAll && !selectedItems.length) {
            return false
        }
        setIsOpenModalEnvioCorreos(true)
    }
    const handleChange = (e, page) => {

        setCurrentPage(page)
        setEnableSearch(true)
    }

    const changeSelectedItem = (e) => {

        const itemId = parseInt(e.target.value);
        console.log(itemId)
        setIsSelectedAll(false)
        setSelectedItems(prevSelectedItems => {
            // console.log('e.target.checked',e.target.checked)
            if (e.target.checked) {
                return [...prevSelectedItems, itemId];
            } else {
                return prevSelectedItems.filter(item => item !== itemId);
            }
        });


    }

    const DeleteCompetitor = async (itemId) => {

        if (window.confirm('¿Estás seguro de eliminar este competidor?')) {
            await apiSecure.post(PATH_DELETE_COMPETIDOR, { token: localStorage.getItem('token'), email: itemId.email })
            setCurrentPage(1)
            setEnableSearch(true)
        }

    }

    const renderCompetidorList = (item) => {

        return (

            <tr key={item.id}>
                <td className="min-w-120px">

                    <input className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                        type='checkbox'
                        value={item.id}
                        checked={selectedItems.includes(item.id)}
                        onChange={changeSelectedItem}
                    />
                </td>
                <td className="min-w-120px">{item.fullname}</td>
                <td className="min-w-120px">{item.email}</td>
                {/* <td className="min-w-120px">{item.carrera}</td> */}
                {/* <td className="min-w-120px">{item.categoria}</td> */}
                {/* <td className="min-w-120px">{item.localidad}</td> */}
                <td className="min-w-120px">
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => DeleteCompetitor(item)}>
                        <i className="fa fa-trash"></i> Eliminar
                    </button>
                </td>

            </tr>
        )
    }

    const selectedAllItems = (e) => {

        if (e.target.checked) {
            setIsSelectedAll(true)
            setSelectedItems(competidorsList.map(item => item.id))
        } else {
            setIsSelectedAll(false)
            setSelectedItems([])
        }

    }

    return (
        <div id="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" >

            <div className="d-flex flex-column flex-root">

                <div className="page d-flex flex-row flex-column-fluid">

                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <Nav active={'competidores'} />

                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="toolbar" id="kt_toolbar">

                                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack mb-4">

                                    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">

                                        <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Sección de Competidores

                                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        </h1>

                                    </div>


                                </div>

                            </div>

                            <div className="post d-flex flex-column-fluid" id="kt_post">

                                <div id="kt_content_container" className="container-xxl">

                                    <div className="row gy-5 g-xl-8">



                                        <div className="col-xl-11">

                                            <div className="card card-xl-stretch mb-5 mb-xl-8">

                                                <div className="card-body ">
                                                    <input className={"form-control form-control-md  "}
                                                        id="email_test"
                                                        type="text"
                                                        name="email_test"
                                                        autoComplete="off"
                                                        placeholder='Email Pruebas'
                                                        value={emailTest}
                                                        onChange={(e) => onChangeCorreoPrueba(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="card-header border-0 pt-5">
                                                    <h3 className="card-title align-items-start flex-column">

                                                        <span className="text-muted mt-1 fw-bold fs-7">LISTADO DE COMPETIDORES DE LOS DIFERENTES EVENTOS</span>
                                                    </h3>
                                                    <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="">

                                                        <span href="#" className="btn btn-sm btn-light btn-primary mx-3"
                                                            onClick={() => modalImportExcellCompetidores()}>

                                                            <span className="svg-icon svg-icon-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black" />
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black" />
                                                                </svg>
                                                            </span>
                                                            Importar Excell
                                                        </span>

                                                        <span href="#" className={`btn btn-sm btn-light btn-warning ${((!isSelectedAll && !selectedItems.length)) ? "not-allowed" : ""}`}
                                                            onClick={() => modalEnviarCorreos()}>

                                                            <span className="svg-icon svg-icon-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black" />
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black" />
                                                                </svg>
                                                            </span>
                                                            Enviar correos de Publicidad
                                                        </span>
                                                    </div>
                                                </div>



                                                <div className="card-body py-3">

                                                    <label>Filtro de Carreras</label>
                                                    <div className='d-flex justify-content-center'>
                                                        <Select2Multiple options={carrerasList} onChangeFilter={onChangeFilter} />
                                                    </div>


                                                    <div className="table-responsive">
                                                        <span className="text-muted mt-1 fw-bold fs-7">Seleccionados: {isSelectedAll ? totalRegister : selectedItems.length} </span>


                                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                                            <thead>
                                                                <tr className="fw-bolder text-muted">
                                                                    <th className="min-w-120px">

                                                                        <input className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                                                                            type='checkbox'
                                                                            checked={isSelectedAll}
                                                                            value={`all-page-${currentPage}`} onChange={selectedAllItems} />
                                                                    </th>

                                                                    <th className="min-w-120px">Nombre</th>
                                                                    <th className="min-w-120px">Email</th>
                                                                    {/* <th className="min-w-120px">Evento</th> */}
                                                                    {/* <th className="min-w-120px">Categoría</th> */}
                                                                    {/* <th className="min-w-120px">Localidad</th> */}
                                                                    <th className="min-w-120px"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (competidorsList.length > 0) ?
                                                                        competidorsList.map(renderCompetidorList)
                                                                        :
                                                                        (<>
                                                                            <tr className='text-center'>
                                                                                <td colSpan={6}>

                                                                                    <p className='text-muted'> No data available.</p>
                                                                                </td>
                                                                            </tr>

                                                                        </>)

                                                                }

                                                            </tbody>


                                                        </table>

                                                        <div className='d-flex justify-content-center'>

                                                            <Pagination
                                                                count={lastPage}
                                                                size="small"
                                                                page={currentPage}
                                                                onChange={handleChange}
                                                                variant='outlined'
                                                            />

                                                        </div>
                                                        <span className="text-muted mt-1 fw-bold fs-7">Total Registros: {totalRegister}</span> <span className="text-muted mt-1 fw-bold fs-7">Paginas: {lastPage}</span>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>

            <ModalImportExcell isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />

            {(isOpenModalEnvioCorreos) && (
                <ModalEnvioCorreos selectedItems={selectedItems} email_prueba={emailTest} isSelectedAll={isSelectedAll}
                    isOpenModal={isOpenModalEnvioCorreos} setIsOpenModal={setIsOpenModalEnvioCorreos} carrerasListSelected={carrerasListSelected} />
            )}

            <ScrollTop />
        </div>
    )
}
