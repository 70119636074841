import React, { useEffect, useRef, useState } from 'react'
import apiSecure from '../../services/servicedba'
import { PATH_ENVIAR_CORREOS_COMPETIDORES } from '../../services/utils'


export default function ModalEnvioCorreos({ isOpenModal, setIsOpenModal, selectedItems, isSelectedAll, carrerasListSelected, email_prueba = "" }) {

    const [loading, setLoading] = useState(false)

    // useEffect(()=>{

    //     console.log('carrerasListSelected',carrerasListSelected)
    // },[carrerasListSelected])
    const [errors, setErrors] = useState({
        successResponse: "",
        errorsResponse: "",
        title: "",
        subtitle: ""
    })

    const [classFade, setClassFade] = useState('')
    const [styleDisplay, setStateDisplay] = useState('none')

    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    // const [links_text, setLinks_text] = useState('')

    // const [fileData, setFileData] = useState('')

    const [errorGlobal, setErrorGlobal] = useState([])

    // const [inputFormInsert, setInputFormInsert] = useState({ flayer: [], link: [], text: [] })
    const [blocks, setBlocks] = useState([]);
    const [blockValues, setBlockValues] = useState([]);

    const modalRef = useRef();
    const inputFile = useRef();

    useEffect(() => {
        setErrors(errorGlobal)
    }, [errorGlobal])


    useEffect(() => {

        if (isOpenModal) {
            setClassFade('show')
            setStateDisplay('block')
            setLoading(false)
            setErrorGlobal([])
            // setFileData('')
            setTitle('')
            setSubtitle('')
            if (inputFile.current) {
                inputFile.current.value = null;  // Limpiar el valor del campo de archivo
            }
        }

    }, [isOpenModal])


    const closeModal = () => {
        if (!loading) {

            setClassFade('')
            setStateDisplay('none')
            setIsOpenModal(false)
            // setEnableSearch(true)
        }

    }


    // const handleFileChange = (e) => {
    //     const selectedFile = e.target.files[0];
    //     setFileData(selectedFile);
    // };

    // const handleSubmitCreateEvent = async (event) => {
    //     event.preventDefault()
    //     setLoading(true)

    //     if (!title) {
    //         setErrorGlobal({ ...errors, title: 'Debe colocar un titulo para continuar.' })
    //         setLoading(false)
    //         return false
    //     }

    //     if (!subtitle) {
    //         setErrorGlobal({ ...errors, subtitle: 'Debe colocar un subtitulo para continuar.' })
    //         setLoading(false)
    //         return false
    //     }



    //     const formData = new FormData();

    //     formData.append('title', title);
    //     formData.append('subtitle', subtitle);
    //     formData.append('file', fileData);
    //     formData.append('selectedItems', selectedItems);
    //     formData.append('isSelectedAll', isSelectedAll);
    //     formData.append('token', localStorage.getItem('token'));
    //     formData.append('carrerasListSelected', carrerasListSelected);

    //     formData.append('links_fields', links_text);



    //     // console.log(selectedItems)
    //     // console.log(isSelectedAll)
    //     // console.log(carrerasListSelected)

    //     const config = {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         }
    //     };

    //     try {

    //         // let { data } = await apiSecure.post(PATH_ENVIAR_CORREOS_COMPETIDORES, formData, config)
    //         await apiSecure.post(PATH_ENVIAR_CORREOS_COMPETIDORES, formData, config)
    //         // console.log('data', data)

    //         // if (data.message) {
    //         setErrorGlobal({ successResponse: "Se enviaron los correos al proceso de queue", errorsResponse: "" })
    //         if (inputFile.current) {
    //             inputFile.current.value = null;  // Limpiar el valor del campo de archivo
    //         }
    //         setLoading(false)
    //         setTitle('')
    //         setSubtitle('')
    //         // }

    //     } catch (error) {

    //         console.log(error)
    //         const { response } = error;
    //         if (response) {

    //             const { data } = response

    //             if (data?.message) {
    //                 setErrorGlobal({ ...errors, errorsResponse: data?.message })
    //                 setLoading(false)
    //             }
    //         }
    //     }

    // }
    const handleSubmitCreateEvent = (event) => {
        event.preventDefault()

        // console.log('blockValues', blockValues)
        setLoading(true)

        if (!title) {
            setErrorGlobal({ ...errors, title: 'Debe colocar un titulo para continuar.' })
            setLoading(false)
            return false
        }

        if (!subtitle) {
            setErrorGlobal({ ...errors, subtitle: 'Debe colocar un subtitulo para continuar.' })
            setLoading(false)
            return false
        }
        // console.log('carrerasListSelected22',carrerasListSelected)
        const formData = new FormData();
        formData.append('title', title);
        formData.append('subtitle', subtitle);

        formData.append('token', localStorage.getItem('token'));
        formData.append('carrerasListSelected', carrerasListSelected);
        // formData.append('links_fields', links_text);
        // formData.append('file', fileData);
        formData.append('selectedItems', selectedItems);
        formData.append('isSelectedAll', isSelectedAll);
        formData.append('email_prueba', email_prueba);
        
        
        // Agregar los bloques dinámicos
        // Crear un array para todos los bloques
        const dynamicBlocks = blocks.map((block, index) => {
            const blockValue = block
            // console.log('blockValue', block)
            switch (block.type) {

                case 'flayer':
                    return {
                        type: 'flayer',
                        file: blockValue?.file ? blockValue.file.name : null, // Nombre del archivo
                    };
                case 'link_text':
                    return {
                        type: 'link_text',
                        linkLabel: blockValue?.linkLabel || '',
                        link: blockValue?.link || '',
                    };
                case 'text':
                    return {
                        type: 'text',
                        text: blockValue?.text || '',
                    };
                default:
                    return null;
            }
        }).filter(block => block !== null);

        // Serializar el array y añadirlo al FormData
        formData.append('dynamicBlocks', JSON.stringify(dynamicBlocks));

        // Agregar archivos separados (porque FormData no soporta JSON de archivos)
        blocks.forEach((block) => {
            // console.log('Block',block)
            if (block.type === 'flayer' && block.file) {
                formData.append(`files[${block.id}]`, block.file); // Asegúrate de que 'block.file' tenga el archivo
            }
        });

        const formDataObject = {};
        formData.forEach((value, key) => {
            // Si el valor es un archivo, puedes opcionalmente mostrar solo el nombre del archivo
            if (value instanceof File) {
                formDataObject[key] = value.name;
            } else {
                formDataObject[key] = value;
            }
        });

        // Imprimir el objeto como JSON en consola
        // console.log(JSON.stringify(formDataObject, null, 2));

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        };

        // Llamar a la API sin esperar la respuesta
        const sendEmailToQueue = () => {
                // enviar
            // Enviar la solicitud y no esperar a la respuesta
            apiSecure.post(PATH_ENVIAR_CORREOS_COMPETIDORES, formData, config)
                .then((data) => {
                    console.log(data);
                    setErrorGlobal({ ...errors, successResponse: "Se enviaron los correos al proceso de queue", errorsResponse: "" });
                    if (inputFile.current) {
                        inputFile.current.value = null;  // Limpiar el valor del campo de archivo
                    }
                    setBlocks([])
                })
                .catch((error) => {
                    console.log(error);
                    const { response } = error;
                    if (response) {
                        const { data } = response;
                        if (data?.message) {
                            setErrorGlobal({ ...errors, errorsResponse: data?.message })
                        }
                    }
                })
                .finally(() => {
                    // Aquí se asegura de que los estados de carga y campos se actualicen
                    setLoading(false);
                    setTitle('');
                    setSubtitle('');
                });
        };

        // Llamar a la función sin esperar su respuesta
        sendEmailToQueue();
    }

    // const addInputForm = (form) => {
    //     // const [inputFormInsert, setInputFormInsert] = useState({ flayer: [], link: [], text: [] })

    // }


    const addBlock = (type) => {
        setBlocks((prev) => [...prev, { type, id: prev.length }]);
    }
    const removeBlock = (id) => {
        setBlocks((prev) => prev.filter((block) => block.id !== id));
        setBlockValues((prev) => {
            const newValues = [...prev];
            newValues[id] = null; // Limpiar el valor asociado al bloque eliminado
            return newValues;
        });
    };
    // Manejar cambios en los valores de los inputs dinámicos
    const handleChange = (blockId, field, file) => {
        // Aquí se actualiza el estado o el bloque con el archivo
        setBlocks((prevBlocks) => {
            return prevBlocks.map((block) => {
                // if (block.id === blockId) {
                return { ...block, [field]: file }; // Establecer el archivo en el bloque correspondiente
                // }
                // return block;
            });
        });
    };


    return (
        <div>

            <div className={"modal fade " + classFade} style={{ display: styleDisplay }} id="kt_modal_invite_friends" tabIndex="-1" aria-hidden="true" data-backdrop="static" aria-labelledby="staticBackdrop" ref={modalRef}>

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">

                        <div className="modal-header pb-0 border-0 justify-content-end">

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1" onClick={() => closeModal()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            <form className="form w-100 " id="kt_sign_in_form_add" action="#" onSubmit={handleSubmitCreateEvent} noValidate>
                                <div className="text-center mb-13">

                                    <h1 className="mb-3">Enviar Corrreo Competidores Seleccionados</h1>
                                    <div className="text-muted fw-bold fs-5">
                                        Los campos marcados con <label className='text-danger'>rojo</label>  son obligatorios
                                    </div>
                                </div>

                                {
                                    (errors.errorsResponse) ? (<>
                                        <div className="alert alert-danger" role="alert">
                                            {errors.errorsResponse}
                                        </div>
                                    </>
                                    ) : null
                                }
                                {
                                    (errors.successResponse) ? (<>
                                        <div className="alert alert-success" role="alert">
                                            {errors.successResponse}
                                        </div>
                                    </>
                                    ) : null
                                }



                                <div className="separator d-flex flex-center mb-8">
                                    <span className="text-uppercase bg-body fs-7 fw-bold text-muted px-3"></span>
                                </div>

                                <div className='row'>
                                    <div className="fv-row mb-2 text-center">
                                        <div>
                                            {
                                                (!loading) ? <>
                                                    <button className="btn btn-sm btn-light btn-success" disabled={loading}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black">
                                                                </rect></svg>
                                                        </span>Guardar
                                                    </button>
                                                </> : null
                                            }
                                            {
                                                (loading) ? <>

                                                    <span className={"indicator-progress d-block"}>Enviado correos...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        {/* <p>Favor no cerrar la pantalla hasta que termine de importar los datos</p> */}
                                                    </span>
                                                </> : null
                                            }

                                        </div>


                                    </div>


                                    <div className="fv-row mb-2">
                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="titulo">Ingrese un Titulo</label>

                                        <input className={"form-control form-control-lg  " + ((!title) ? " is-invalid" : "")}
                                            id="titulo"
                                            type="text"
                                            name="titulo"
                                            autoComplete="off"
                                            placeholder='Título'
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                        />
                                        <div className={'invalid-feedback ' + ((errors.title && errors.title === "") ? " d-block" : null)}>{errors.title}</div>
                                    </div>

                                    <div className="fv-row mb-2">
                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="subtitulo">Ingrese un Subtitulo</label>

                                        <input className={"form-control form-control-lg  " + ((!subtitle) ? " is-invalid" : "")}
                                            id="subtitulo"
                                            type="text"
                                            name="subtitulo"
                                            autoComplete="off"
                                            placeholder='Subtitulo'
                                            value={subtitle}
                                            onChange={(e) => setSubtitle(e.target.value)}
                                            required
                                        />
                                        <div className={'invalid-feedback ' + ((errors.subtitle && errors.subtitle === "") ? " d-block" : null)}>{errors.subtitle}</div>
                                    </div>
                                    <div className="fv-row mb-2 d-flex justify-content-start align-items-center gap-2">
                                        <button type="button" className="btn btn-info btn-sm" onClick={() => addBlock('flayer')}>
                                            <i className="fa fa-plus"></i>
                                            Agregar Flayer
                                        </button>
                                        <button type="button" className="btn btn-info btn-sm" onClick={() => addBlock('link_text')}>
                                            <i className="fa fa-plus"></i>
                                            Agregar Enlace con texto
                                        </button>
                                        <button type="button" className="btn btn-info btn-sm" onClick={() => addBlock('text')}>
                                            <i className="fa fa-plus"></i>
                                            Agregar Campo de texto
                                        </button>
                                    </div>


                                    {/* Renderizar bloques dinámicos */}
                                    {blocks.map((block, index) => {
                                        switch (block.type) {
                                            case 'flayer':
                                                return (
                                                    <div key={`flayer${block.id}`} className="fv-row mb-4">
                                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor={`flayer-${block.id}`}>
                                                            Insertar Imagen/Flayer
                                                        </label>
                                                        <input
                                                            className="form-control form-control-lg"
                                                            ref={inputFile}
                                                            id={`flayer-${block.id}`}
                                                            type="file"
                                                            name={`flayer-${block.id}`}
                                                            autoComplete="off"
                                                            placeholder="Insertar imagen"
                                                            accept="image/*"
                                                            onChange={(e) => handleChange(block.id, 'file', e.target.files[0])}
                                                        />
                                                        <div
                                                            className={'invalid-feedback' + (blockValues[block.id]?.fileError ? ' d-block' : '')}
                                                        >
                                                            {blockValues[block.id]?.fileError}
                                                        </div>
                                                        <button
                                                            className="btn btn-danger btn-sm mt-2 bt-minimum"
                                                            onClick={() => removeBlock(block.id)}
                                                        >

                                                            Eliminar input
                                                        </button>
                                                    </div>
                                                );
                                            case 'link_text':
                                                return (
                                                    <div key={`link_text${block.id}`} className="fv-row mb-4">
                                                        <label className="form-label fs-6 fw-bolder text-dark">
                                                            Insertar enlace y texto
                                                        </label>
                                                        <input
                                                            className="form-control form-control-lg mb-3"
                                                            id={`link-label-${block.id}`}
                                                            type="text"
                                                            placeholder="Ingrese texto de enlace"
                                                            onChange={(e) => handleChange(block.id, 'linkLabel', e.target.value)}
                                                        />
                                                        <input
                                                            className="form-control form-control-lg"
                                                            id={`link-${block.id}`}
                                                            type="text"
                                                            placeholder="Ingrese Link"
                                                            onChange={(e) => handleChange(block.id, 'link', e.target.value)}
                                                        />
                                                        <button
                                                            className="btn btn-danger btn-sm mt-2 bt-minimum"
                                                            onClick={() => removeBlock(block.id)}
                                                        >

                                                            Eliminar input
                                                        </button>
                                                    </div>
                                                );
                                            case 'text':
                                                return (
                                                    <div key={`text${block.id}`} className="fv-row mb-4">
                                                        <label className="form-label fs-6 fw-bolder text-dark">
                                                            Insertar Texto
                                                        </label>
                                                        <textarea
                                                            id={`text-${block.id}`}
                                                            className="form-control form-control-lg"
                                                            placeholder="Ingrese texto"

                                                            onChange={(e) => handleChange(block.id, 'text', e.target.value)}
                                                        />
                                                        <button
                                                            className="btn btn-danger btn-sm mt-2 bt-minimum"
                                                            onClick={() => removeBlock(block.id)}
                                                        >

                                                            Eliminar input
                                                        </button>
                                                    </div>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}


                                    {/* <div className="fv-row mb-4">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="address">Insertar Imagen/Flayer</label>

                                        <input className={"form-control form-control-lg "}
                                            ref={inputFile}
                                            id="file"
                                            type="file"
                                            name="file"
                                            autoComplete="off"
                                            placeholder='Insertar imagen'
                                            accept="image/*"// Atributo para aceptar solo archivos image/*   
                                            onChange={handleFileChange}
                                        />

                                        <div className={'invalid-feedback' + ((errors.fileimport) ? " d-block" : null)}>{errors.fileimport}</div>

                                    </div>

                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="address">Insertar enlace y texto</label>

                                        <input className={"form-control form-control-lg mb-3 "}
                                            id="link_or_text_label"
                                            type="text"
                                            name="link_or_text_label"
                                            autoComplete="off"
                                            placeholder='Ingrese texto de enlace'
                                            value={links_text}
                                            onChange={(e) => setLinks_text(e.target.value)}

                                        />
                                        <input className={"form-control form-control-lg  "}
                                            id="link_or_text"
                                            type="text"
                                            name="link_or_text"
                                            autoComplete="off"
                                            placeholder='Ingrese Link'
                                            value={links_text}
                                            onChange={(e) => setLinks_text(e.target.value)}

                                        />
                                    </div>

                                    <div className="fv-row mb-2">


                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="address">Insertar Texto</label>

                                        <textarea id="text" className={"form-control form-control-lg "} placeholder='Ingrese Link o texto'></textarea>
                                    </div> */}


                                </div>
                            </form>

                        </div>


                    </div>

                </div>

            </div >


        </div >
    )
}
